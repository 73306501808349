import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

const Message = ({ content, link }) => {
  useEffect(() => {
    $(".messageHeader").slideDown(1000);
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <div className="messageHeader">
      <div className="messageContent py-2">
        <Link style={{ color: "black" }} to={link}>
          <p className="text-center">
            {content}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Message;
