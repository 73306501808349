import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos"; // Animate on scroll
import Brochure from "./Brochure";
import FeedbackSection from "../components/FeedbackSection";
import Community from "../common/Community";

const Services = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const handleShowVideo = () => {
    $(".clickedVideo").slideDown(1000);
    setTimeout(() => setShowVideo(true), 1500);
  };
  const handleRemoveVideo = () => setShowVideo(false);

  return (
    <div className="skilledContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Richmond Post-Acute Care | Short Term Post-Acute Care Services
        </title>
        <meta
          name="description"
          content="Richmond Post-Acute Care provides top quality nursing care, short term post-acute care and other exceptional services for patients around the Richmond Area."
        />
        <link rel="canonical" href="https://www.richmondpostacute.com/" />
      </Helmet>

      <div className="mainSliders">
        <div className="fluid-container">
          <OwlCarousel
            className="owl-theme"
            items={1}
            loop={true}
            autoplay={true}
            dots={false}
            nav
            navText={[
              "<i class='fas fa-angle-left fa-3x'></i>",
              "<i class='fas fa-angle-right fa-3x'></i>",
            ]}
            autoplayTimeout={10000}
          >
            <div className="item" style={{ background: 'linear-gradient(to bottom, #4e9630, #D3D34B)' }}>
              <img
                src="/images/nursing-homes-shortterm.png"
                alt="High Performing Badge"
                className='badge-img'
                style={{ objectFit: 'contain' }}
              />
              <div className="overlay">
                <div
                  data-aos="fade-down"
                  data-aos-offset="300"
                  data-aos-once="true"
                >
                  <h1>Preferred Choice for Skilled Nursing Excellence</h1>
                  <p>
                    We are thrilled to announce that Richmond Post-Acute Care has been recognized in the 2025 U.S. News Best Nursing Homes rankings, earning the prestigious &quot;High Performing&quot; rating!
                  </p>
                  <button className="btn btn-primary" onClick={() => window.location.href = '/award'}>
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div>
            <div className="item">
              <img
                src="/images/images-short-term-care.webp"
                alt="Richmond Post-Acute Care Short Term Care"
                style={{ backgroundPositionY: "top" }}
              />
              <div className="overlay">
                <div
                  data-aos="fade-down"
                  data-aos-offset="300"
                  data-aos-once="true"
                >
                  <h1>Welcome to Richmond Post-Acute Care</h1>
                  <p>
                    We are a locally owned and operated skilled nursing
                    facility in Richmond, CA offering 24- hour nursing care
                    and Post-Acute Rehabilitation.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <img
                src="/images/images-richmond-therapy-programs.webp"
                alt="Richmond Post-Acute Care Innovative Therapy Programs"
              />
              <div className="overlay">
                <div
                  data-aos="fade-down"
                  data-aos-offset="300"
                  data-aos-once="true"
                >
                  <h1>Innovative Therapy Programs</h1>
                  <p>
                    Get the most out of your therapy time with the best
                    supervision of our highly trained and licensed nurses that
                    are dedicated in helping a person's physical,
                    psychological and overall well-being.
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <div className="skillednursingOverview my-3">
        <div className="container">
          <div data-aos="fade-down" data-aos-once="true">
            <p>
              Richmond Post-Acute Care has become an in-demand facility for
              anybody requiring short term post-acute care. Our team of highly
              trained staff are committed to providing the highest level of
              care as well as customer service to create a truly exceptional
              resident experience.
            </p>
            <p className="py-3">
              The focus of our care is to help each resident recover and
              improve their mobility so they can return home. Our nursing and
              rehab staff are committed to helping each resident reach their
              goals. We have created an environment that is clean,
              comfortable, and without compromise on amenities in order to
              help each resident focus on their improvement.
            </p>
          </div>
        </div>
      </div>

      <div className="mainVideo">
        <div className="container-fluid">
          <div
            className="clickedVideo"
            style={{ display: showVideo ? "block" : "none" }}
          >
            <div className="removeVod p-3 text-right">
              <i
                className="fa fa-times fa-2x"
                aria-hidden="true"
                style={{ color: "#fff", cursor: "pointer" }}
                onClick={handleRemoveVideo}
                title="Hide Video"
              />
            </div>
            <div
              className="vodContainer d-flex justify-content-center align-items-center"
              style={{ height: "85%" }}
            >
              <div className="vodContent">
                {!showVideo ? (
                  <i
                    className="fa fa-spinner fa-4x"
                    id="vodLoader"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <video controls autoPlay>
                    <source
                      src="https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondpostacutecareassets/Richmond_JJ_Testimony_1.mp4"
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            </div>
          </div>

          <div className="videoContainer">
            <video autoPlay={!isMobile} muted loop>
              <source
                src="https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondpostacutecareassets/Richmond_JJ_Testimony_1.mp4"
                type="video/mp4"
              />
            </video>
            <div className="container">
              <div className="videoOverlay">
                <div
                  data-aos="fade-down"
                  data-aos-offset="300"
                  data-aos-once="true"
                >
                  <h1>Experience Enhanced Care</h1>
                  <p>
                    At Richmond Post-Acute Care, we're dedicated to providing
                    our patients with exceptional care and a positive
                    experience. Our team is committed to delivering meticulous
                    planning and open communication every step of the way. We
                    always put our patients first and strive to exceed your
                    expectations.
                  </p>
                  <button className="btn btn-primary" onClick={handleShowVideo}>
                    WATCH THE VIDEO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" show={showModal} onHide={closeModal}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/JOqp3guTUZA?&autoplay=1"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>

      {/* Feedback Section */}
      <FeedbackSection />

      <Brochure />

      <Community />
      <div
        className="letushelpContainer shadow-sm p-3"
        data-aos="fade-down"
        data-aos-offset="300"
        data-aos-once="true"
      >
        <div className="fluid-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h3 className="py-3">Let Us Help You</h3>
                <p className="py-3">
                  At our facility, we go above and beyond to make sure our
                  patients receive exceptional care. Our nursing staff is
                  dedicated to delivering top-notch healthcare and hospitality
                  services, because we know that a warm, welcoming environment
                  can make all the difference.
                </p>
                <p className="py-3">
                  Our commitment is to help you achieve your health goals by
                  maintaining clear and open communication every step of the
                  way. You can trust us to provide the care and support you need
                  to thrive.
                </p>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <img
                  src="/images/help-image.webp"
                  width={"100%"}
                  height={"auto"}
                  alt="Richmond Post-Acute Care Let Us Help You"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
