import React from 'react';
import { Typography, Grid, Paper, Card, CardContent, withStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container, Row, Col, Table } from 'react-bootstrap';
import AOS from "aos"; // Animate on scroll
import { Helmet } from "react-helmet";

const imageUrl = 'https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondstaging/award-hero.jpg';

const styles = theme => ({
    root: {
        // backgroundColor: '#f5f5f5',
        paddingBottom: theme.spacing.unit * 4,
    },
    header: {
        color: '#fff',
        textAlign: 'center',
        padding: theme.spacing.unit * 8,
    },
    headerContent: {
        maxWidth: '1200px',
        margin: '0 auto',
    },
    badgeSection: {
        padding: theme.spacing.unit * 4,
        textAlign: 'center',
    },
    badgeImage: {
        maxWidth: '200px',
        margin: 'auto',
    },
    button: {
        marginTop: theme.spacing.unit * 2,
    },
    gridContainer: {
        marginTop: theme.spacing.unit * 4,
    },
    gridItem: {
        textAlign: 'center',
        padding: theme.spacing.unit * 2,
    },
    gridImage: {
        maxWidth: '100px',
        margin: 'auto',
    },
    statsSection: {
        backgroundColor: '#fff',
        padding: theme.spacing.unit * 6,
        textAlign: 'center',
    },
    statCard: {
        margin: theme.spacing.unit * 2,
    },
    heading: {
        fontSize: '24px',
        paddingBottom: '20px',
        color: 'black'
    },
    paragraph: {
        fontSize: '16px',
        paddingBottom: '20px',
    },
    listItems: {
        lineHeight: '1.5',
        paddingTop: '10px'
    },
});

function AwardPage({ classes }) {
    AOS.init();
    return (
        <div className={classes.root}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Richmond Post-Acute Care Recognized for High Performing Excellence
                </title>
                <meta
                    name="description"
                    content="Richmond Post-Acute Care has been honored with the High Performing Recognition award, showcasing our commitment to exceptional short-term rehabilitation and patient care."
                />
                <link rel="canonical" href="https://www.richmondpostacute.com/award" />
            </Helmet>
            <div className="item-gradient" style={{ background: 'linear-gradient(to bottom, #4e9630, #D3D34B)' }}>
                <div className={classes.header} data-aos="fade-down">
                    <div className={classes.headerContent}>
                        <div className="row image-content justify-content-center align-items-center">
                            <div className="d-none d-lg-block col-lg-3">
                                <img
                                    src="/images/leaf-right-award.png"
                                    alt="Award Leaf Left"
                                    className={classes.badgeImage}
                                />
                            </div>
                            <div className="col-lg-2">
                                <img
                                    src="https://richmondpostacutecarebucket.s3.us-west-1.amazonaws.com/richmondstaging/nursing-homes-shortterm.svg"
                                    alt="High Performing Badge"
                                    className={classes.badgeImage}
                                    height="200px"
                                />
                            </div>
                            <div className="d-none d-lg-block col-lg-3">
                                <img
                                    src="/images/leaf-left-award.png"
                                    alt="Award Leaf Right"
                                    className={classes.badgeImage}
                                />
                            </div>



                        </div>
                        <h1 className='text-white py-3'>
                            High Performing Recognition
                        </h1>
                        <p className='text-white pb-3' style={{ color: '#000' }}>
                            Richmond Post-Acute Care has been awarded the "High Performing" rating, placing it among the top 19% of skilled nursing facilities.
                        </p>
                    </div>
                </div>
            </div>
            <Container className="my-5">
                <Row>
                    <Col>
                        <h3 className={classes.heading}>Richmond Post-Acute Care: Your Top Choice for Skilled Nursing Excellence</h3>
                        <p className={classes.paragraph}>
                            Celebrating Excellence in Post-Acute Care
                            Richmond Post-Acute Care has been proudly recognized as a "High Performing" facility in the 2025 U.S. News Best Nursing Homes rankings! Among nearly 15,000 skilled nursing facilities nationwide, we are honored to be the only facility in our region to achieve this prestigious distinction.
                        </p>
                        <h4 className='pb-3'>
                            Why Choose Richmond Post-Acute Care?
                        </h4>
                        <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                            <li className={classes.listItems}><strong>5-Star CMS Rating: </strong>
                                We maintain a 5-Star CMS rating, reflecting our unwavering commitment to providing exceptional short-term rehabilitation and specialized care.</li>
                            <li className={classes.listItems}><strong>Elite Post-Acute Services </strong>
                                Our dedicated team and state-of-the-art facilities deliver personalized care tailored to your loved one's unique needs, ensuring a smooth path to recovery.</li>
                            <li className={classes.listItems}><strong>Trusted by Families, Recommended by Experts: </strong>
                                As a leader in skilled nursing and rehabilitation, Richmond Post-Acute Care is the preferred choice for families seeking compassionate, high-quality care.</li>
                        </ul>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default withStyles(styles)(AwardPage);